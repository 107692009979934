import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, List, Typography } from "antd";

import DashboardContext from "./DashboardContext";

export default function SurveyList() {
	const { dataSource } = useContext(DashboardContext);

	const navigate = useNavigate();

	if (dataSource && dataSource.faculties && dataSource.faculties.length) {
		return (
			<List
				bordered
				dataSource={dataSource.faculties}
				renderItem={(item, index) => {
					return (
						<List.Item
							className={
								index !== dataSource.faculties.length - 1 ? "mb-10" : ""
							}
						>
							<div className="wrapper-content">
								<div className="content">
									<Typography.Text>{item.faculty_name}</Typography.Text>
									<Typography.Text> - </Typography.Text>
									<Typography.Text>{item.subject}</Typography.Text>
								</div>

								<div className="action">
									<Button
										className="btn-add-tertiary"
										onClick={async () => {
											navigate(`/evaluation/${item.id}`);
										}}
									>
										Take Survey
									</Button>
								</div>
							</div>
						</List.Item>
					);
				}}
			/>
		);
	} else {
		return (
			<div className="div-no-survey-list">
				<div className="content">No Survey Available</div>
			</div>
		);
	}
}
