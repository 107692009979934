import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Modal, notification, Typography } from "antd";
import dayjs from "dayjs";

import { POST } from "../../../../providers/useAxiosQuery";
import { userData } from "../../../../providers/companyInfo";
import {
	checkNetworkStatus,
	readFile,
	setFileStorage,
} from "../../../../providers/useCapacitorStorage";
import notificationErrors from "../../../../providers/notificationErrors";
import QuestionOptionPreview from "./QuestionOptionPreview";

export default function ModalPreviewAnswer(props) {
	const {
		toggleModalPreview,
		setToggleModalPreview,
		dataFormInfo,
		dataFacultyInfo,
	} = props;

	console.log("dataFacultyInfo: ", dataFacultyInfo);

	const navigate = useNavigate();

	const [isLoadingSurvey, setIsLoadingSurvey] = useState(false);

	const { mutate: mutateSurvey } = POST(
		`api/form_question_answer`,
		"form_question_answer_single_store"
	);

	const onFinishPreview = async () => {
		let dataSurvey1 = dataFormInfo;

		let dataSurvey2 = [];

		dataFormInfo.form_question_categories.forEach((item) => {
			let questionAnswers = [];
			item.form_questions.forEach((item2) => {
				questionAnswers.push({
					profile_id: userData().profile_id,
					faculty_load_id: dataFacultyInfo.faculty_load_id,
					subject_id: dataFacultyInfo.subject_id,
					semester_id: dataFacultyInfo.semester_id,
					school_year_id: dataFacultyInfo.school_year_id,
					form_id: dataFormInfo.id,
					date_start_answer: dataFormInfo.date_start_answer,
					date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					form_question_category_id: item.id,
					form_question_id: item2.id,
					answer: Array.isArray(item2.answer)
						? JSON.stringify(item2.answer)
						: item2.answer,
					created_by: userData().id,
					created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
				});
			});
			dataSurvey2.push(...questionAnswers);
		});

		setIsLoadingSurvey(true);
		checkNetworkStatus().then(async (res) => {
			let readFileTemp = await readFile(
				"dsac_fsuu_evaluation/db_pending_list.txt"
			);
			let data = [];
			let findData = null;

			console.log("readFileTemp: ", readFileTemp);

			if (readFileTemp && readFileTemp.data) {
				data = JSON.parse(readFileTemp.data);
				findData = data.findIndex(
					(f) =>
						Number(f.id) === Number(dataFormInfo.id) &&
						f.profile_id === userData().profile_id
				);
			}

			if (!res.connected) {
				let globalLoading = document.querySelector(".globalLoading");
				if (globalLoading && !globalLoading.matches(".hide")) {
					globalLoading.classList.add("hide");
				}

				if (findData === -1) {
					data.push(dataSurvey1);
				} else {
					data[findData] = dataSurvey1;
				}

				setFileStorage("dsac_fsuu_evaluation", "db_pending_list", data).then(
					() => {
						setIsLoadingSurvey(false);
						notification.info({
							message: "Evaluation",
							description:
								"You are currently offline, and your evaluation has been saved on this device.",
						});
						navigate(-1);
					}
				);
			} else {
				handleSaveEvaluationOnline(dataSurvey2, data);
			}
		});
	};

	const handleSaveEvaluationOnline = (dataSurvey2, data) => {
		mutateSurvey(
			{ data: dataSurvey2 },
			{
				onSuccess: (res2) => {
					if (res2.success) {
						notification.success({
							message: "Evaluation",
							description: res2.message,
						});

						data = data.filter(
							(f) =>
								Number(f.id) !== Number(dataFormInfo.id) &&
								f.profile_id === userData().profile_id
						);

						setFileStorage(
							"dsac_fsuu_evaluation",
							"db_pending_list",
							data
						).then(() => {
							setIsLoadingSurvey(false);
							navigate(-1);
						});
					} else {
						setIsLoadingSurvey(false);
						notification.error({
							message: "Evaluation",
							description: res2.message,
						});
					}
				},
				onError: (err) => {
					setIsLoadingSurvey(false);
					notificationErrors(err);
				},
			}
		);
	};

	return (
		<Modal
			wrapClassName="modal-preview-answer"
			open={toggleModalPreview}
			title={
				dataFormInfo && dataFormInfo.form_name ? dataFormInfo.form_name : ""
			}
			onCancel={() => setToggleModalPreview(false)}
			footer={
				<>
					<Button
						size="large"
						onClick={() => setToggleModalPreview(false)}
						loading={isLoadingSurvey}
						className="btn-main-primary outlined br-30"
					>
						Close
					</Button>
					<Button
						size="large"
						onClick={() => onFinishPreview()}
						loading={isLoadingSurvey}
						className="btn-main-primary br-30"
					>
						Submit
					</Button>
				</>
			}
		>
			<Card>
				Faculty Name:{" "}
				{dataFacultyInfo && dataFacultyInfo.faculty_name ? (
					<Typography.Title level={4}>
						{dataFacultyInfo.faculty_name} - {dataFacultyInfo.subject}{" "}
					</Typography.Title>
				) : (
					""
				)}
			</Card>
			{dataFormInfo && dataFormInfo.form_question_categories
				? dataFormInfo.form_question_categories.map((item, index) => {
						return (
							<Card
								key={index}
								title={
									<span
										className="quill-output category"
										dangerouslySetInnerHTML={{
											__html: item.category,
										}}
									/>
								}
							>
								{item.form_questions && item.form_questions
									? item.form_questions.map((item2, index2) => {
											return (
												<div
													key={index2}
													className={`question-wrapper ${
														item2.question_type !== "break" ? "border" : ""
													}`}
												>
													<div
														className="quill-output question"
														dangerouslySetInnerHTML={{
															__html: item2.question,
														}}
													/>
													<QuestionOptionPreview data={item2} />
												</div>
											);
									  })
									: ""}
							</Card>
						);
				  })
				: ""}
		</Modal>
	);
}
