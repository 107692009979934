import React, { useEffect } from "react";
import { Button, Layout } from "antd";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGifts, faRefresh } from "@fortawesome/pro-regular-svg-icons";

import { bgColor, name } from "../../providers/companyInfo";
import useNetworkStatusChange from "../../providers/useNetworkStatusChange";
import ClearCache from "../../providers/ClearCache";
import Header from "./Header";
import Footer from "./Footer";

export default function Private(props) {
	const { children, title, subtitle, pageHeaderIcon, pageHeaderClass, pageId } =
		props;

	useNetworkStatusChange();

	useEffect(() => {
		if (title) {
			document.title = title + " | " + name;
		}
		return () => {};
	}, [title]);

	return (
		<ClearCache>
			{({ isLatestVersion, emptyCacheStorage }) => (
				<>
					{!isLatestVersion && (
						<div className="notification-notice">
							<div className="notification-notice-content">
								<div className="notification-notice-icon">
									<FontAwesomeIcon icon={faGifts} />
								</div>
								<div className="notification-notice-message">
									<div className="title">Updates Now Available</div>
									<div className="description">
										A new version of this Web App is ready
									</div>
									<div className="action">
										<Button
											onClick={(e) => {
												e.preventDefault();
												emptyCacheStorage();
											}}
											icon={<FontAwesomeIcon icon={faRefresh} />}
										>
											Refresh
										</Button>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="globalLoading hide">
						<SpinnerDotted thickness="100" color={bgColor} enabled={true} />
					</div>

					<Layout className="private-layout" id={pageId ?? ""}>
						<Header
							pageHeaderClass={pageHeaderClass}
							pageHeaderIcon={pageHeaderIcon}
							title={title}
							subtitle={subtitle}
							pageId={pageId}
						/>

						<Layout.Content>{children}</Layout.Content>

						<Footer />
					</Layout>
				</>
			)}
		</ClearCache>
	);
}
