import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row, Typography } from "antd";
import dayjs from "dayjs";

import { GET } from "../../../providers/useAxiosQuery";
import { role, userData } from "../../../providers/companyInfo";
import {
	checkPermissions,
	deleteFile,
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import QuestionOption from "./components/QuestionOption";
import FloatSelect from "../../../providers/FloatSelect";
import ModalPreviewAnswer from "./components/ModalPreviewAnswer";

export default function PageSurveyForm() {
	const params = useParams();
	const [form] = Form.useForm();
	const userdata = userData();

	const [toggleModalPreview, setToggleModalPreview] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [dataPending, setDataPending] = useState([]);
	const [dataFacultyInfo, setDataFacultyInfo] = useState(null);
	const [dataFormInfo, setDataFormInfo] = useState(null);

	GET(
		`api/mobile_form?from=${
			role() === "Student" ? "SurveyMobileStudent" : "SurveyMobileFaculty"
		}`,
		"mobile_form_list",
		(res) => {
			let datasync = document.getElementById("datasync");
			if (datasync) {
				datasync.classList.remove("hide");
			}

			let data = {
				forms: res.data,
				faculties: res.dataFaculty,
			};

			setDataSource(data);

			checkPermissions().then((res1) => {
				if (res1) {
					if (res1.publicStorage === "granted") {
						setFileStorage("dsac_fsuu_evaluation", "db", data).then((res2) => {
							if (!res2 && !res2.uri) {
								deleteFile("dsac_fsuu_evaluation/db.txt").then((res3) => {
									if (!res3) {
										setFileStorage("dsac_fsuu_evaluation", "db", data).then(
											(res4) => {
												if (res2 && res2.uri) {
													setTimeout(() => {
														if (datasync) {
															datasync.classList.add("hide");
														}
													}, 1000);
												}
											}
										);
									}
								});
							} else {
								setTimeout(() => {
									if (datasync) {
										datasync.classList.add("hide");
									}
								}, 1000);
							}
						});
					}
				}
			});
		},
		false
	);

	useEffect(() => {
		checkPermissions().then((res1) => {
			if (res1) {
				if (res1.publicStorage === "granted") {
					readFile("dsac_fsuu_evaluation/db.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setDataSource(res2);
						}
					});

					readFile("dsac_fsuu_evaluation/db_pending_list.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setDataPending(res2);
						}
					});
				}
			}
		});

		return () => {};
	}, []);

	useEffect(() => {
		if (dataSource && dataSource.faculties) {
			console.log("dataSource: ", dataSource);

			let findData = dataSource.faculties.find(
				(item) => Number(item.id) === Number(params.id)
			);

			setDataFacultyInfo(findData);
		}

		return () => {};
	}, [dataSource, params]);

	const onFinish = (values) => {
		let form_question_categories = dataFormInfo.form_question_categories.map(
			(item) => {
				let form_questions = item.form_questions.map((item2) => {
					let answer = values[`q_${item2.id}`] ?? "";

					if (item2.question_type === "date") {
						if (values[`q_${item2.id}`]) {
							answer = dayjs(values[`q_${item2.id}`]).format("YYYY-MM-DD");
						}
					}

					return {
						...item2,
						profile_id: userData().profile_id,
						faculty_load_id: dataFacultyInfo.faculty_load_id,
						subject_id: dataFacultyInfo.subject_id,
						semester_id: dataFacultyInfo.semester_id,
						school_year_id: dataFacultyInfo.school_year_id,
						form_id: dataFormInfo.id,
						date_start_answer: dataFormInfo.date_start_answer,
						date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						form_question_category_id: item.id,
						form_question_id: item2.id,
						answer: Array.isArray(answer) ? JSON.stringify(answer) : answer,
						created_by: userData().id,
						created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					};
				});

				return {
					...item,
					form_questions,
				};
			}
		);

		let dataSourceCopy = {
			...dataFormInfo,
			form_question_categories,
			date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			survey_status: "Ready to Upload",
		};

		setDataFormInfo(dataSourceCopy);
		setTimeout(() => {
			setToggleModalPreview(true);
		}, 500);
	};

	const RenderQuestions = () => {
		if (dataFormInfo) {
			return dataFormInfo.form_question_categories.map((item, index) => {
				return (
					<Card
						key={index}
						title={
							<span
								className="quill-output category"
								dangerouslySetInnerHTML={{
									__html: item.category,
								}}
							/>
						}
					>
						<Row gutter={[20, 20]}>
							{item.form_questions && item.form_questions
								? item.form_questions.map((item2, index2) => {
										return (
											<Col xs={24} sm={24} md={24} lg={24} xl={24} key={index2}>
												<div
													className="quill-output question"
													dangerouslySetInnerHTML={{
														__html: item2.question,
													}}
												/>

												<QuestionOption data={item2} />
											</Col>
										);
								  })
								: []}
						</Row>
					</Card>
				);
			});
		} else {
			return <Typography.Text>No questions found.</Typography.Text>;
		}
	};

	return (
		<Form
			form={form}
			onFinish={onFinish}
			scrollToFirstError={{
				behavior: "smooth",
				block: "middle",
			}}
		>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{dataFacultyInfo && dataFacultyInfo.faculty_name ? (
						<Typography.Title level={4}>
							{dataFacultyInfo.faculty_name} - {dataFacultyInfo.subject}{" "}
						</Typography.Title>
					) : (
						""
					)}
					<Typography.Title level={4}>
						FACULTY NAME:{" "}
						{dataFacultyInfo && dataFacultyInfo.faculty_name
							? dataFacultyInfo.faculty_name
							: "No Faculty Name"}{" "}
						-{" "}
						{dataFacultyInfo && dataFacultyInfo.subject
							? dataFacultyInfo.subject
							: "No Subject"}
					</Typography.Title>
				</Col>

				<Col xs={24} sm={24} md={12} lg={10} xl={10}>
					<Form.Item name="form_id">
						<FloatSelect
							label="Form"
							placeholder="Form"
							className="form-select-form"
							popupClassName="float-select-popup-form"
							options={
								dataSource && dataSource.forms
									? dataSource.forms.map((item) => ({
											label: item.form_name,
											value: item.id,
									  }))
									: []
							}
							onChange={(e) => {
								if (e) {
									let value = Number(e);
									let formData = dataSource.forms.find(
										(f) => Number(f.id) === value
									);
									console.log("formData: ", formData);

									if (formData) {
										let findPending = dataPending.find(
											(x) =>
												Number(x.id) === value &&
												x.profile_id === userdata().profile_id
										);

										formData = {
											...formData,
											form_question_categories:
												formData.form_question_categories.map((item2) => {
													let findPendingQuestionCategories =
														findPending?.form_question_categories.find(
															(x) => Number(x.id) === Number(item2.id)
														);
													return {
														...item2,
														form_questions: item2.form_questions.map(
															(item3) => {
																let findPendingQuestion =
																	findPendingQuestionCategories?.form_questions.find(
																		(x) => Number(x.id) === Number(item3.id)
																	);

																return {
																	...item3,
																	answer:
																		findPendingQuestion &&
																		findPendingQuestion.answer
																			? findPendingQuestion.answer
																			: null,
																};
															}
														),
													};
												}),
										};

										setDataFormInfo(formData);
									}
								}
							}}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item shouldUpdate noStyle>
						{() => {
							return <RenderQuestions />;
						}}
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
					<Button
						htmlType="submit"
						type="primary"
						className="btn-main-primary br-30"
						size="large"
					>
						Preview
					</Button>
				</Col>
			</Row>

			<ModalPreviewAnswer
				toggleModalPreview={toggleModalPreview}
				setToggleModalPreview={setToggleModalPreview}
				dataFormInfo={dataFormInfo}
				dataFacultyInfo={dataFacultyInfo}
			/>
		</Form>
	);
}
